
import { Component, Ref, Vue } from 'vue-property-decorator'
import { activeEditJobsQuery, searchAll } from '../ts/editing'
import { ActionCategory, EditDataType, EditJob, EditJobPriority, EditJobSource, editPriorityValue, Market, Products } from '@ht-lib/accounts-models'
import EditJobModal from './EditJobModal.vue'
import { getImageEditingUsers, getUserWithStore, UserProfileMap, UserProfileWithId } from '../ts/userStore'
import { format, fromUnixTime } from 'date-fns'
import { cloneDeep } from 'lodash'

const marketLookup = { S: 'Schools', G: 'Grads' }

interface ColumnFilters {
  title: {
    contains: string | null
  }
  department: {
    includes: EditJobSource[]
  }
  product: {
    includes: Products[]
  }
  action: {
    includes: ActionCategory[]
  }
  market: {
    includes: string[]
  }
  createTime: {
    from: string | null
    to: string | null
  }
  headCount: {
    min: number | null
    max: number | null
  }
  createdBy: {
    includes: string[]
  }
  assignedTo: {
    includes: string[]
  }
  priority: {
    includes: EditJobPriority[]
  }
}

interface Option {
  label: string
  value: string
}

interface Row {
  index: number
  title: string
  jobSource: EditJobSource // Department
  product: Products
  actionCategory: ActionCategory // Action
  market: string
  createTime: number
  headCount: number
  createdUser: string
  assignedUserId: string
  priority: EditJobPriority
}

interface Pagination {
  sortBy: string
  descending: boolean
  page: number
  rowsPerPage: number
}

@Component({
  name: 'EditJobsTable',
  components: {
    EditJobModal
  }
})
export default class extends Vue {
  @Ref() editModal: EditJobModal

  snap !: () => void
  jobs: EditJob[] = []
  loading = true
  users: UserProfileMap = {}
  searchTerm = ''
  loadingSearch = false
  searching = false

  dayInSeconds = 86400

  reactivityHack = 0

  pageLoaded = false

  // editJobsTableEnableFilter
  enableFilter = true

  dataTypes = Object.values(EditDataType)
  jobSources = Object.values(EditJobSource) // Department
  priorityOptions = Object.values(EditJobPriority)
  actionOptions = Object.values(ActionCategory)
  productOptions = Object.values(Products)
  imageEditingUsers: UserProfileWithId[] = []

  devMode = false
  demoMode = false

  visibleColumns = [
    'index',
    'title',
    'action',
    'department',
    'product',
    'market',
    'createTime',
    'headCount',
    'createdBy',
    'assignedTo',
    'priority',
    'actions'
  ]

  // editJobsTablePagination
  pagination: Pagination = {
    sortBy: 'priority',
    descending: false,
    page: 1,
    rowsPerPage: 200
  }

  paginationRowOptions = [50, 200, 500, 1000, 0]

  // editJobsTableFilters
  columnFilters: ColumnFilters = {
    title: {
      contains: null
    },
    department: {
      includes: []
    },
    product: {
      includes: []
    },
    action: {
      includes: []
    },
    market: {
      includes: []
    },
    createTime: {
      from: null, // Date
      to: null // Date
    },
    headCount: {
      min: null, // number
      max: null // number
    },
    createdBy: {
      includes: []
    },
    assignedTo: {
      includes: []
    },
    priority: {
      includes: []
    }
  }

  columnFiltersDefault = {} // Set in created

  columns = [
    {
      label: '#',
      name: 'index',
      filterable: false,
      sortable: true,
      align: 'center'
    },
    {
      label: 'Title',
      name: 'title',
      filterable: true,
      sortable: true,
      align: 'center',
      field: (r: EditJob): string => r.title
    },
    {
      label: 'Department',
      name: 'department',
      filterable: true,
      sortable: true,
      align: 'center',
      field: (r: EditJob): EditJobSource => r.jobSource
    },
    {
      label: 'Product',
      name: 'product',
      filterable: true,
      sortable: true,
      align: 'center',
      field: (r: EditJob): Products => r.product
    },
    {
      label: 'Action',
      name: 'action',
      filterable: true,
      sortable: true,
      align: 'center',
      field: (r: EditJob): ActionCategory => r.actionCategory
    },
    {
      label: 'Market',
      name: 'market',
      filterable: true,
      sortable: true,
      align: 'center',
      field: (r: EditJob): Market => r.market
    },
    {
      label: 'Date Created',
      name: 'createTime',
      filterable: true,
      sortable: true,
      align: 'center',
      field: (r: EditJob): number => r.createTime
    },
    {
      label: 'Head Count',
      name: 'headCount',
      filterable: true,
      sortable: true,
      align: 'center',
      field: (r: EditJob): number => r.headCount
    },
    {
      label: 'Created By',
      name: 'createdBy',
      filterable: true,
      sortable: true,
      align: 'center',
      field: (r: EditJob): string => r.createdUser
    },
    {
      label: 'Assigned To',
      name: 'assignedTo',
      filterable: true,
      sortable: true,
      align: 'center',
      field: (r: EditJob): string => r.assignedUserId
    },
    {
      label: 'Priority',
      name: 'priority',
      filterable: true,
      sortable: true,
      align: 'center',
      field: (r: EditJob): number => editPriorityValue(r),
      sort: (priorityA: number, priorityB: number, rowA: EditJob, rowB: EditJob): number => {
        return priorityB - priorityA ||
          // rowA.title.localeCompare(rowB.title, undefined, { sensitivity: 'base' }) ||
          rowA.createTime - rowB.createTime
      }
    },
    {
      label: 'Actions',
      name: 'actions',
      filterable: false,
      sortable: false,
      align: 'center'
    }
  ]

  get imageEditingUserOptions (): Option[] {
    const users = this.imageEditingUsers.map(x => ({
      label: x.displayName,
      value: x.id
    }))
    users.push({
      label: 'SYSTEM',
      value: 'SYSTEM'
    })
    return users
  }

  // No future date
  dateFilter (dateString: string): boolean {
    // dateString in YYYY/MM/DD format
    return new Date(dateString) <= new Date()
  }

  resetFilterToDefault (col: string): void {
    Vue.set(this.columnFilters, col, this.columnFiltersDefault[col])
    // this.columnFilters[col] = this.columnFiltersDefault[col]
  }

  filterSource (col: string): string[] | Option[] {
    switch (col) {
      case 'department':
        return this.jobSources
      case 'product':
        return this.productOptions
      case 'action':
        return this.actionOptions
      case 'createdBy':
      case 'assignedTo':
        return this.imageEditingUserOptions
      case 'priority':
        return this.priorityOptions
      default:
        throw new Error('Invalid column for filterSource')
    }
  }

  filterApplied (col: string): boolean {
    switch (col) {
      case 'title':
        return this.columnFilters.title.contains != null && this.columnFilters.title.contains !== ''
      case 'department':
      case 'product':
      case 'action':
      case 'createdBy':
      case 'assignedTo':
      case 'priority':
        return this.columnFilters[col].includes.length !== this.filterSource(col).length
      case 'market':
        return this.columnFilters.market.includes.length !== 2
      case 'createTime':
        return this.columnFilters.createTime.from != null || this.columnFilters.createTime.to != null
      case 'headCount':
        return this.columnFilters.headCount.min != null || this.columnFilters.headCount.max != null
      default:
        return false
    }
  }

  isDropdown (col: string): boolean {
    return ['action', 'department', 'product', 'createdBy', 'assignedTo', 'priority'].includes(col)
  }

  filterSelectCurrentState (col: string): boolean | null {
    const filterLength = this.columnFilters[col].includes.length
    const sourceLength = this.filterSource(col).length

    if (filterLength === 0) {
      return false
    } else if (filterLength === sourceLength) {
      return true
    } else {
      return null
    }
  }

  filterSelectToggleState (col: string, newState: boolean): void {
    if (newState) {
      this.columnFilters[col].includes = this.filterSource(col).map(x => typeof x === 'string' ? x : x.value)
    } else {
      this.columnFilters[col].includes = []
    }
  }

  get hasFilterApplied (): boolean {
    return Object.keys(this.columnFilters).some(x => this.filterApplied(x))
  }

  showEditJobModal (job: EditJob | null): void {
    this.editModal.showModal(job)
  }

  assignedUser (uid: string): string {
    return this.users[uid]?.displayName || 'Unassigned'
  }

  createdUser (uid: string): string {
    if (uid === 'SYSTEM') return uid
    return this.users[uid]?.displayName || 'Not Found'
  }

  formatUnixToDate (unix: number): string {
    return format(fromUnixTime(unix), 'HH:mm - dd MMM')
  }

  openJob (job: EditJob): void {
    // Moved logic to /editing/view component mounted
  //   if (!job.assignedUserId || job.status === EditJobStatus.UNASSIGNED) {
  //     await updateEditJob(job.id, { assignedUserId: this.$auth.user.uid, status: EditJobStatus.IN_PROGRESS })
  //   }
  //   if (job.assignedUserId === this.$auth.user.uid && job.status === EditJobStatus.ASSGINED) {
  //     await updateEditJob(job.id, { status: EditJobStatus.IN_PROGRESS })
  //   }
    this.$router.push(`/editing/view/${ job.id }`)
  }

  marketString (marketCode: string): string {
    return marketLookup[marketCode]
  }

  clearSearch (): void {
    this.setupSnap()
    this.searching = false
  }

  async search (): Promise<void> {
    this.cleanup()
    this.loadingSearch = true
    const docs = await searchAll(this.searchTerm)
    this.setJobs(docs)
    console.log(docs)
    this.loadingSearch = false
    this.searching = true
  }

  async setJobs (jobs: EditJob[]): Promise<void> {
    if (this.demoMode) {
      const baseJob = jobs[0]
      this.jobSources.push('Unknown' as EditJobSource)
      this.jobs = this.jobSources.map(jobSource => {
        const newJob = cloneDeep(baseJob)
        newJob.jobSource = jobSource
        return newJob
      })
    } else {
      this.jobs = jobs
    }

    const map: UserProfileMap = {}
    await Promise.all(
      this.jobs.map(async x => {
        if (x.createdUser && x.createdUser !== 'SYSTEM') {
          map[x.createdUser] = await getUserWithStore(x.createdUser)
        }
        if (x.assignedUserId) {
          map[x.assignedUserId] = await getUserWithStore(x.assignedUserId)
        }
      })
    )
    this.users = map
    this.loading = false
  }

  setupSnap (): void {
    this.cleanup()
    this.snap = activeEditJobsQuery().onSnapshot(snap => {
      this.setJobs(snap.docs.map(x => x.data()))
    })
  }

  cleanup (): void {
    if (this.snap) {
      this.snap()
    }
  }

  saveFilters (): void {
    this.$q.localStorage.set('editJobsTableFilters', this.columnFilters)
    console.log('Saved filters: ', this.columnFilters)
    this.$q.notify({
      message: 'Filters saved',
      color: 'positive',
      position: 'bottom'
    })
  }

  configureFilters (): void {
    const cachedFilters = this.$q.localStorage.getItem<ColumnFilters | undefined>('editJobsTableFilters')

    const userIdMap = this.imageEditingUserOptions.map(x => x.value)

    const defaultFilters = {
      title: {
        contains: null
      },
      department: {
        includes: this.jobSources
      },
      product: {
        includes: this.productOptions
      },
      action: {
        includes: this.actionOptions
      },
      market: {
        includes: ['S', 'G']
      },
      createTime: {
        from: null, // Date
        to: null // Date
      },
      headCount: {
        min: null, // number
        max: null // number
      },
      createdBy: {
        includes: userIdMap
      },
      assignedTo: {
        includes: userIdMap
      },
      priority: {
        includes: this.priorityOptions
      }
    }

    this.columnFiltersDefault = defaultFilters
    this.columnFilters = cachedFilters ?? cloneDeep(defaultFilters)
  }

  async created (): Promise<void> {
    this.imageEditingUsers = await getImageEditingUsers()
    this.setupSnap()
    this.configureFilters()
    this.enableFilter = this.$q.localStorage.getItem<boolean>('editJobsTableEnableFilter') ?? true
    this.pagination = this.$q.localStorage.getItem<Pagination>('editJobsTablePagination') ?? this.pagination
    this.pageLoaded = true
    this.reactivityHack++
  }

  beforeDestroy (): void {
    this.cleanup()
  }

  // https://v1.quasar.dev/style/color-palette#color-list
  jobSourceColour (jobSource: EditJobSource): string {
    switch (jobSource) {
      case EditJobSource.CUSTOMER_SERVICE:
        return 'bg-blue-grey-5'
      case EditJobSource.DIRECT_ORDERES:
        return 'bg-pink-3'
      case EditJobSource.EXCEPTIONS:
        return 'bg-light-green-5'
      case EditJobSource.GIFTS:
        return 'bg-lime-5'
      case EditJobSource.GRADUATIONS:
        return 'bg-red-2'
      case EditJobSource.ORDER_ENTRIES:
        return 'bg-purple-3'
      case EditJobSource.SALES:
        return 'bg-deep-purple-3'
      case EditJobSource.SANDHURST:
      case EditJobSource.MILITARIY:
      case EditJobSource.PIRBRIGHT:
        return 'bg-green-6'
      case EditJobSource.MARKETING:
        return 'bg-brown-4'
      case EditJobSource.PACKING:
        return 'bg-blue-4'
      case EditJobSource.PREMIER:
        return 'bg-light-blue-8'
      case EditJobSource.PRINTING:
        return 'bg-cyan-11'
      case EditJobSource.QC:
        return 'bg-deep-orange-4'
      case EditJobSource.IMAGE_EDITING:
        return 'bg-teal-3'
      case EditJobSource.OTHER:
        return 'bg-orange-5'
      case EditJobSource.AUTOMATIC:
        return ''
      default:
        return 'bg-yellow-14'
    }
  }

  priorityColour (priority: EditJobPriority): string {
    if (priority === EditJobPriority.URGENT) {
      return 'deep-orange-8'
    }
    if (priority === EditJobPriority.PRIORITY) {
      return 'amber-14'
    }
    return 'teal-3'
  }

  updateFilterValue (col: string, value: unknown): void {
    console.log('Updating filter value for column: ', col, ' with value: ', value)
    Vue.set(this.columnFilters, col, value)
  }

  updateEnableFilter (enableFilter: boolean): void {
    console.log('updateEnableFilter Event', enableFilter)
    this.$q.localStorage.set('editJobsTableEnableFilter', enableFilter)
    this.reactivityHack++
  }

  updatePagination (pagination: Pagination): void {
    if (!this.pageLoaded) return
    console.log('updatePagination Event', pagination)

    this.$q.localStorage.set('editJobsTablePagination', pagination)
  }

  resetAllSettings (): void {
    this.$q.localStorage.remove('editJobsTableFilters')
    this.$q.localStorage.remove('editJobsTableEnableFilter')
    this.$q.localStorage.remove('editJobsTablePagination')
    this.$q.notify({
      message: 'All settings reset',
      color: 'positive',
      position: 'bottom'
    })

    Vue.set(this, 'columnFilters', cloneDeep(this.columnFiltersDefault))
    this.enableFilter = true
    this.pagination = {
      sortBy: 'priority',
      descending: false,
      page: 1,
      rowsPerPage: 200
    }
  }

  filterData (rows: Row[]): Row[] {
    console.log('Filtering data with options: ', this.columnFilters)
    const out = rows.filter(row => {
      if (!this.enableFilter) {
        return true
      }
      if (this.filterApplied('title')) {
        if (!row.title.includes(this.columnFilters.title.contains)) {
          return false
        }
      }
      if (this.filterApplied('department')) {
        if (!this.columnFilters.department.includes.includes(row.jobSource)) {
          return false
        }
      }
      if (this.filterApplied('product')) {
        if (!this.columnFilters.product.includes.includes(row.product)) {
          return false
        }
      }
      if (this.filterApplied('action')) {
        if (!this.columnFilters.action.includes.includes(row.actionCategory)) {
          return false
        }
      }
      if (this.filterApplied('market')) {
        if (!this.columnFilters.market.includes.includes(row.market)) {
          return false
        }
      }
      if (this.filterApplied('createTime')) {
        if (this.columnFilters.createTime.from && row.createTime < parseInt(this.columnFilters.createTime.from)) {
          return false
        }
        if (this.columnFilters.createTime.to && row.createTime > (parseInt(this.columnFilters.createTime.to) + this.dayInSeconds)) {
          return false
        }
      }
      if (this.filterApplied('headCount')) {
        if (this.columnFilters.headCount.min && row.headCount < this.columnFilters.headCount.min) {
          return false
        }
        if (this.columnFilters.headCount.max && row.headCount > this.columnFilters.headCount.max) {
          return false
        }
      }
      if (this.filterApplied('createdBy')) {
        if (!this.columnFilters.createdBy.includes.includes(row.createdUser)) {
          return false
        }
      }
      if (this.filterApplied('assignedTo')) {
        if (!this.columnFilters.assignedTo.includes.includes(row.assignedUserId)) {
          return false
        }
      }
      if (this.filterApplied('priority')) {
        if (!this.columnFilters.priority.includes.includes(row.priority)) {
          return false
        }
      }
      return true
    })
    return out
  }
}
